<template>
  <div>
    <vx-card>
      <div v-if="$apollo.loading || changeLoading">
        <p>Cargando...</p>
      </div>
      <div v-else>
        <ValidationObserver
          ref="validateFormFranchiseUpdate"
          v-slot="{ invalid }"
        >
          <section class="vx-row">
            <div class="vx-col sm:w-1/2 w-full mb-3">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required|max:150"
                name="Nombre"
              >
                <vs-input
                  class="w-full"
                  label="Nombre"
                  placeholder="Nombre"
                  v-model="inventory.name"
                />
                <span class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="vx-col sm:w-1/2 w-full mb-3">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required|max:150"
                name="Descripción"
              >
                <vs-input
                  class="w-full"
                  label="Descripción"
                  placeholder="Descripción"
                  v-model="inventory.description"
                />
                <span class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </section>

          <section>
            <div class="vx-row mt-10 mb-3">
              <div class="vx-col sm:w-1/2">
                <vs-button
                  v-if="mode == 'edit'"
                  type="border"
                  @click="confirmDelete"
                >
                  Eliminar
                </vs-button>
              </div>
              <div class="vx-col sm:w-1/2 space-x-3">
                <vs-button @click="performMutation" :disabled="invalid">
                  Guardar
                </vs-button>
                <vs-button type="border" @click="$router.go(-1)">
                  Descartar
                </vs-button>
              </div>
            </div>
          </section>
        </ValidationObserver>
      </div>
    </vx-card>
  </div>
</template>

<script>
import { INVENTORY_ITEM } from '@/graphql/queries';
import {
  CREATE_INVENTORY,
  UPDATE_INVENTORY,
  DELETE_INVENTORY,
} from '@/graphql/mutations';
export default {
  props: {
    mode: {
      type: String,
      default: 'edit',
    },
  },

  data() {
    return {
      inventory: {
        id: null,
        name: '',
        description: '',
      },
      changeLoading: false,
    };
  },

  apollo: {
    inventory: {
      query: INVENTORY_ITEM,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      skip() {
        return this.mode == 'create';
      },
      result({ data }) {
        console.log('🌵', data);
        this.inventory = {
          id: data?.inventoryItem?.id,
          name: data?.inventoryItem?.name,
          description: data?.inventoryItem?.description,
        };
      },
    },
  },

  methods: {
    performMutation() {
      if (this.mode == 'edit') {
        this.updateInventory();
      } else {
        this.createInventory();
      }
    },

    updateInventory() {
      this.changeLoading = true;
      this.$apollo
        .mutate({
          mutation: UPDATE_INVENTORY,
          variables: {
            input: {
              inventory_id: this.inventory.id,
              name: this.inventory.name,
              description: this.inventory.description,
            },
          },
        })
        .then(() => {
          this.changeLoading = false;
          this.$vs.notify({
            color: 'success',
            title: '¡Inventario actualizado!',
            text: 'El inventario fue actualizado correctamente.',
          });
          this.$router.push(`/inventario/productos`);
        })
        .catch((error) => {
          this.changeLoading = false;
          this.$vs.notify({
            color: 'danger',
            title: 'Inventario no actualizado',
            text: `El inventario no fue actualizado. ${error?.message}`,
          });
          console.error('❌', error);
        });
    },

    createInventory() {
      this.changeLoading = true;

      this.$apollo
        .mutate({
          mutation: CREATE_INVENTORY,
          variables: {
            input: {
              name: this.inventory.description,
              description: this.inventory.name,
            },
          },
        })
        .then(() => {
          this.changeLoading = false;
          this.$vs.notify({
            color: 'success',
            title: 'Inventario creada!',
            text: 'El inventario fue creada correctamente.',
          });
          this.$router.push(`/inventario/productos`);
        })
        .catch((error) => {
          this.changeLoading = false;
          this.$vs.notify({
            color: 'danger',
            title: 'Inventario no creada',
            text: `El producto del inventario no fue creado. ${error?.message}`,
          });
          console.error('❌', error);
        });
    },

    confirmDelete() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: `Confirmar eliminación`,
        text: `¿Seguro que quieres eliminar el producto del inventario?`,
        accept: this.deleteInventory,
        acceptText: 'Eliminar',
        cancelText: 'Cancelar',
      });
    },

    deleteInventory() {
      this.changeLoading = true;
      this.$apollo
        .mutate({
          mutation: DELETE_INVENTORY,
          variables: {
            id: this.inventory.id,
          },
        })
        .then(() => {
          this.changeLoading = false;
          this.$vs.notify({
            color: 'success',
            title: 'Producto del inventario eliminado!',
            text: 'El producto fue eliminado correctamente.',
          });
          this.$router.push(`/inventario/productos`);
        })
        .catch((error) => {
          this.changeLoading = false;
          this.$vs.notify({
            color: 'danger',
            title: 'El producto de inventario no eliminado.',
            text: `El producto no fue eliminado. ${error?.message}`,
          });
          console.error('❌', error);
        });
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/vuexy/apps/simple-calendar.scss';

.simple-calendar {
  &__swatches-custom-trigger {
    height: 41px;
    width: 100%;
    border: 1px solid #d7e2e8;
  }
}
.vue-swatches__trigger__wrapper {
  width: 100%;
}
</style>
